import { useMutation } from "@tanstack/vue-query"
import type { HTTPError } from "ky"

export function useForgotPasswordMutation() {
  const popup = usePopup()
  const { changeAuthPage } = useLogin()
  const { t: $t } = useI18n({ useScope: "global" })

  const forgotPasswordMutation = useMutation({
    mutationFn: AuthService.forgotPassword,
    onSuccess: () => changeAuthPage("passwordresetawait"),
    onError: (e: HTTPError) => handleApiError(e),
  })

  function handleApiError(error: HTTPError) {
    switch (error?.response.status) {
      case 429:
        return popup.open({
          title: $t("authentication.blocked.header_title"),
          content: $t("authentication.apiErrors.too_many_tries"),
        })
    }
  }

  return {
    forgotPasswordMutation,
  }
}
