<template>
  <form class="auth-manager__form" novalidate @submit.prevent="onSubmit">
    <div class="flex flex-col gap-3 md:gap-4">
      <div v-if="isSignup" :class="cn([classNames.inputs, 'gap-inherit flex flex-row md:flex-col'])">
        <UiInput
          v-model="formState.firstName"
          name="firstName"
          :errors="firstNameErrors"
          :variant="firstNameErrors.length > 0 ? 'error' : 'default'"
          hide-details
          :placeholder="$t('authentication.login.inputs.first_name')"
          :disabled="loading"
          required
          autocomplete="given-name"
          type="text"
          maxlength="80"
          size="adaptive"
          :classes="{ root: 'flex-1', label: 'text-muted-foreground font-semibold' }"
          @blur="() => onBlur('firstName')"
          @input="() => onInput('firstName')"
        />

        <UiInput
          v-model="formState.lastName"
          name="lastName"
          :placeholder="$t('authentication.login.inputs.last_name')"
          hide-details
          :disabled="loading"
          autocomplete="family-name"
          type="text"
          maxlength="80"
          size="adaptive"
          :classes="{ root: 'flex-1', label: 'text-muted-foreground font-semibold' }"
        />
      </div>

      <!-- Email & Password Inputs -->
      <div :class="['auth-manager__form-inputs gap-inherit', classNames.inputs]">
        <UiInput
          v-model="formState.email"
          name="email"
          :errors="emailErrors"
          :variant="emailErrors.length > 0 || apiError.isForgotPassError.value ? 'error' : 'default'"
          :placeholder="$t('authentication.login.inputs.email')"
          :hide-details="!emailErrors.length"
          :disabled="loading"
          required
          autocomplete="email"
          type="email"
          maxlength="80"
          size="adaptive"
          :classes="{ label: 'text-muted-foreground font-semibold' }"
          @blur="() => onBlur('email')"
          @input="() => onInput('email')"
        >
          <template #errors="{ error: err }">
            <i18n-t :keypath="err" scope="global" tag="span">
              <template v-if="isSignup" #login>
                <UiButton
                  class="text-destructive h-0 p-0 font-normal underline"
                  @click.prevent="changeAuthPage('login')"
                  variant="link"
                >
                  {{ $t("authentication.buttons.login").toLowerCase() }}
                </UiButton>
              </template>

              <template #signup>
                <UiButton
                  class="text-destructive h-0 p-0 font-normal underline"
                  v-if="!isSignup"
                  variant="link"
                  @click.prevent="changeAuthPage('signup')"
                >
                  {{ $t("authentication.buttons.signup") }}
                </UiButton>
                <span class="text-destructive">{{ $t("authentication.buttons.signup").toLowerCase() }} </span>
              </template>

              <template #reset_password>
                <UiButton
                  class="text-destructive h-0 p-0 font-normal underline"
                  variant="link"
                  @click.prevent="changeAuthPage('forgotpassword')"
                >
                  {{ $t("authentication.reset_password.buttons.send").toLowerCase() }}
                </UiButton>
              </template>
            </i18n-t>
          </template>
        </UiInput>

        <UiInput
          v-model="formState.password"
          name="password"
          :classes="{ label: 'text-muted-foreground font-semibold' }"
          :variant="passwordErrors.length > 0 || apiError.isForgotPassError.value ? 'error' : 'default'"
          :placeholder="$t('authentication.login.inputs.password')"
          :hide-details="!passwordErrors.length"
          :errors="passwordErrors"
          required
          maxlength="80"
          size="adaptive"
          :disabled="loading"
          :autocomplete="isSignup ? 'new-password' : 'current-password'"
          :type="showPassword ? 'text' : 'password'"
          @blur="() => onBlur('password')"
          @input="() => onInput('password')"
        >
          <template #append>
            <UiButton
              v-if="!!formState.password?.length"
              :key="showPassword ? 'hide' : 'show'"
              variant="link"
              type="button"
              size="sm"
              z-index="1"
              @click="showPassword = !showPassword"
            >
              {{ !showPassword ? $t("common.show") : $t("common.hide") }}
            </UiButton>
          </template>
        </UiInput>
      </div>
    </div>

    <div :class="classNames.footer">
      <!-- Forgot password -->
      <div v-if="!isSignup" :class="['auth-manager__forgot mt-4 leading-none']">
        <a
          class="text-sm/6 font-light text-black/50 underline hover:cursor-pointer"
          tabindex="0"
          @click.prevent="changeAuthPage('forgotpassword')"
        >
          {{ $t("authentication.login.buttons.ghost_forgot_password") }}
        </a>
      </div>

      <UiButton
        :class="cn('mt-10 w-full text-base', classNames.cta)"
        :disabled="v$.$invalid"
        :loading="loading"
        type="submit"
        :id="genClickId('auth', isSignup ? 'signup' : 'login', 'button')"
        :_title="$t('authentication.login.buttons.goto_finq')"
      >
        {{ isSignup ? $t("authentication.buttons.signup") : $t("authentication.login.buttons.goto_finq") }}
      </UiButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core"
import { helpers, minLength, required } from "@vuelidate/validators"
import { cva } from "class-variance-authority"

interface AuthManagerClasses {
  root: ClassValue
  inputs: ClassValue
  cta: ClassValue
  footer: ClassValue
}

const props = defineProps({
  isSignup: { type: Boolean, default: true },
  loading: { type: Boolean, default: false },
  submitted: { type: Boolean, default: false },
  hideGhostLink: { type: Boolean, default: false },
  classes: { type: Object as PropType<AuthManagerClasses>, default: () => ({}) },
  variant: { type: String as PropType<keyof (typeof authManagerVariants)["variant"]>, default: undefined },
})

const emit = defineEmits<{
  (e: "onSubmit", formData: any): void
}>()

const authInputVariants = cva("flex flex-col", {
  variants: {
    variant: { ...authManagerVariants.variant, horizontal: "flex-row [&>div]:flex-1 gap-xxs pb-0" },
  },
})

const user = useUser()
const { t } = useI18n({ useScope: "global" })
const { changeAuthPage } = useLogin()
const { formState, clearApiError } = useAuthManager()
const apiError = useAuthApiError()
const showPassword = ref(false)

onMounted(() => {
  if (user.user.value?.email) formState.email = user.user.value?.email
})

const baseFormValidation = {
  email: { required, minLength: minLength(7), validateEmail },
}

const validations = computed(() => {
  if (!props.isSignup) {
    return baseFormValidation
  } else {
    return {
      firstName: { required },
      password: {
        required,
        pattern: helpers.regex(regexPatterns.password),
      },
      ...baseFormValidation,
    }
  }
})

const v$ = useVuelidate(validations, formState)

const classNames = computed(() => {
  return {
    root: cn(props.classes.root),
    footer: cn("mt-auto", props.classes.footer),
    cta: cn(props.classes.cta),
    inputs: cn(authInputVariants({ variant: props.variant, class: props.classes.inputs as any })),
  }
})

const emailErrors = computed(() => {
  const errors: string[] = []
  const $v = v$.value.email

  if (!$v?.$dirty) return errors

  if ($v.required.$invalid) errors.push("authentication.login.inputs.email_required")
  if ($v.validateEmail.$invalid) errors.push("kyc_common.validation_errors.email_field")
  if (apiError.error?.value?.field === "email") errors.push(apiError.error?.value.message)

  return errors
})

const passwordErrors = computed(() => {
  const errors: string[] = []
  const $v = v$.value.password

  if (!$v?.$dirty) return errors

  if ($v.required.$invalid) errors.push(t("authentication.login.inputs.password_required"))
  if (props.isSignup && $v.pattern.$invalid) errors.push(t("authentication.signup.inputs.password_hint"))

  return errors
})

const firstNameErrors = computed(() => {
  const errors: string[] = []
  const $v = v$.value.firstName

  if (!$v?.$dirty) return errors

  if ($v.required.$invalid) errors.push(t("authentication.login.inputs.first_name_required"))

  return errors
})

const onSubmit = () => {
  if (v$.value.$invalid) return v$.value.$touch()
  const baseFormData = { email: formState.email, password: formState.password }

  const formData = props.isSignup
    ? { ...baseFormData, firstName: formState.firstName, lastName: formState.lastName }
    : baseFormData

  emit("onSubmit", formData)
}

const onBlur = (field: keyof typeof formState) => {
  v$.value?.[field]?.$touch()
  clearApiError()
}

const onInput = (field: keyof typeof formState) => {
  v$.value?.[field]?.$reset()
  clearApiError()
}
</script>

<style lang="scss" scoped>
.auth-manager {
  &__forgot {
    &-error {
      color: theme.$red-pink;

      a {
        font-weight: bold;
      }
    }
  }
}
</style>
