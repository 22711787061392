<template>
  <div class="flex flex-col gap-10">
    <h1 class="text-2xl font-semibold">{{ t("title") }}</h1>

    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-3">
        <AuthSocialLogin />
      </div>

      <UiTextDivider>{{ t("or") }}</UiTextDivider>

      <AuthManager
        :is-signup="false"
        :loading="data.loading"
        :hide-ghost-link="hideGhostLink"
        @onSubmit="submit"
        @onLoading="data.loading = $event"
      />
    </div>

    <i18n-t class="font-light text-black/50 md:text-center" tag="span" keypath="not_signed_up">
      <template #action>
        <UiButton class="h-fit p-0 text-base font-medium" variant="link" @click="changeAuthPage('signup')">
          {{ t("signup") }}
        </UiButton>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
import { HTTPError } from "ky"

const props = defineProps<{ hideGhostLink?: boolean }>()
const emit = defineEmits(["userNavigated", "onClose"])

const user = useUser()
const popup = usePopup()
const apiError = useAuthApiError()
const gtm = useGtm()
const { changeAuthPage, handleSuccess } = useLogin()

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })

const data = ref({ loading: false, email: user.user.value?.email || "", password: "" })

const handleApiErrorPopups = (code: number) => {
  switch (code) {
    case 401:
      return popup.open({ title: $t("authentication.apiErrors.server_error") })

    case 406:
      // If code is Not Acceptable (406) it means the user is signed up but not verified
      // so we redirect to the verify page
      return changeAuthPage("verify")

    case 403:
      return null

    case 429:
      return popup.open({
        title: $t("authentication.blocked.header_title"),
        content: $t("authentication.apiErrors.too_many_tries"),
      })

    case 500:
      popup.open({ title: $t("authentication.apiErrors.general") })
  }
}

const handleApiErrorMessages = (code: number) => {
  switch (code) {
    case 400:
      return {
        message: "authentication.apiErrors.bad_email",
        field: "email",
      }

    case 403:
      return {
        message: "authentication.apiErrors.invalid_mail_or_pass",
        field: "email",
      }
  }
}

const submit = async (form: { email: string; password: string }) => {
  gtm.push({ event: "login_attempt", method: "user-password", email: form.email })

  try {
    data.value.loading = true
    const user = await AuthService.login(form)
    gtm.push({ event: "set_user_data", user_id: user.id, email: form.email })

    handleSuccess()
  } catch (err: any) {
    console.error("Error while submitting login form", err)

    if (!err?.response || !(err instanceof HTTPError)) return

    const code = err.response.status

    apiError.error.value = { code, ...handleApiErrorMessages(code)! } as AuthApiError

    handleApiErrorPopups(code)
  } finally {
    data.value.loading = false
  }
}
</script>

<i18n lang="json">
{
  "he": {
    "title": "כניסה",
    "or": "או",
    "not_signed_up": "חדשים ב־FINQ? {action}",
    "signup": "הרשמה"
  },
  "en": {
    "title": "Log in",
    "or": "or",
    "not_signed_up": "Don’t have an account? {action}",
    "signup": "Sign up"
  }
}
</i18n>
