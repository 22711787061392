<template>
  <UiBaseModal
    v-model="isOpen"
    persistent
    backdrop="white"
    :classes="{
      root: 'z-60 flex h-full flex-1 md:h-fit',
      panelWrapper: 'h-full p-0',
      panel:
        'm-auto flex h-full w-full max-w-none items-center justify-center rounded-none bg-transparent md:m-0 md:items-end',
    }"
  >
    <div
      class="max-w-large relative flex flex-1 flex-col rounded bg-white shadow-[0_1px_4px_0_rgba(0,_0,_0,_0.08)] md:h-fit"
    >
      <div :class="cn('flex items-center justify-between p-4')">
        <span class="flex" id="auth-widget-nav-slot" />

        <UiButton class="ms-auto" variant="icon" @click="handleClose">
          <LucideX :size="20" />
          <span class="sr-only">Close</span>
        </UiButton>
      </div>

      <div
        class="flex flex-col overflow-hidden p-24 pt-11 md:px-5 md:pb-10 md:pt-0"
        v-auto-animate="autoAnimateFade"
      >
        <component :is="is" @onClose="handleClose" />
      </div>
    </div>
  </UiBaseModal>
</template>

<script setup lang="ts">
import type { TAuthComponentsMap } from "@finq/app-base/composables/useLogin"

const emit = defineEmits(["onCLose", "userNavigated"])

const { isOpen, currPage, handleClose, handleSuccess, changeAuthPage } = useLogin()
const { t } = useI18n({ useScope: "global" })

const AuthComponentsMap: Record<TAuthComponentsMap, any> = {
  login: resolveComponent("AuthScreensLogin"),
  forgotpassword: resolveComponent("AuthScreensForgotPassword"),
  signup: resolveComponent("AuthScreensSignupLobby"),
  signupform: resolveComponent("AuthScreensSignupForm"),
  passwordresetawait: resolveComponent("AuthScreensConfirmationWait"),
  verify: resolveComponent("AuthScreensVerify"),
}

const is = computed(() => {
  const currentPage = currPage.value
  if (currentPage) return AuthComponentsMap[currentPage]

  return null
})

const { clearFormState } = useAuthManager()

onUnmounted(() => clearFormState())
</script>

<style lang="scss">
.auth-widget__navbar {
  &-container {
    padding: theme.$spacing-s theme.$spacing-m;
    align-items: flex-start;
  }

  @include theme.media(">md") {
    .navbar__icon--hidden {
      display: none;
    }
  }

  @include theme.media("<md") {
    &-container {
      padding: theme.$spacing-xxs theme.$spacing-s;
    }
  }
}

.auth-widget--min-height {
  @screen md {
    min-height: 450px;
  }
}
</style>
