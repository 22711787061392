<template>
  <UiButton
    class="gap-xxs p-xxs relative h-auto items-center text-base leading-none"
    variant="alternative"
    @click="googleLogin"
    :disabled="disabled"
    :id="genClickId('auth', isSignup ? 'signup' : 'login', 'google_social_button')"
  >
    <UiNuxtIcon class="start-xxs absolute top-1/2 -translate-y-1/2" filled :size="16" name="socials/google" />
    {{ buttonText + " Google" }}
  </UiButton>

  <UiButton
    class="gap-xxs p-xxs relative h-auto items-center text-base leading-none"
    variant="alternative"
    @click="facebookLogin"
    :disabled="disabled"
    :id="genClickId('auth', isSignup ? 'signup' : 'login', 'facebook_social_button')"
  >
    <UiNuxtIcon
      class="start-xxs absolute top-1/2 -translate-y-1/2"
      filled
      :size="16"
      name="socials/facebook"
    />
    {{ buttonText + " Facebook" }}
  </UiButton>
</template>

<script setup lang="ts">
import { HTTPError } from "ky"

import { DiscriminatedDataLayerArg } from "@finq/app-base/composables/analytics/useGtm"

const props = withDefaults(defineProps<{ isSignup?: boolean; disabled?: boolean }>(), {})
const emit = defineEmits(["on-loading", "onLogin"])

const { t } = useI18n()
const popup = usePopup()
const user = useUser()
const gtm = useGtm()
const { handleSuccess, changeAuthPage } = useLogin()

const buttonText = computed(() => {
  return props.isSignup
    ? t("authentication.signup.buttons.register_with")
    : t("authentication.login.buttons.login_with")
})

function getSocialAuthCallback(method: any) {
  return (err: HTTPError, user: any) => {
    emit("on-loading", false)
    if (err) {
      const { response } = err

      if (!response) return

      // if (response.status === 403) forgotpassError = true;
      popup.open({ content: handleApiError(err.response.status) })
    } else if (user) {
      gtm.push({ event: "set_user_data", user_id: user.id, email: user.email })

      handleSuccess()
    }
  }
}

async function facebookLogin() {
  emit("on-loading", true)

  // When the user clicks on button the event 'signup_attempt' should trigger
  gtmSocialSignupEvent("facebook")
  await AuthService.loginWithSocialPopup("facebook", getSocialAuthCallback("facebook"))
}

async function googleLogin() {
  emit("on-loading", true)

  // When the user clicks on button the event 'signup_attempt' should trigger
  gtmSocialSignupEvent("google-oauth2")
  await AuthService.loginWithSocialPopup("google-oauth2", getSocialAuthCallback("google"))
}

/** @param code - Recieves an error code and decide which error to display according to error code */
function handleApiError(code: number) {
  switch (code) {
    case 400:
      return t("authentication.apiErrors.bad_email")

    case 401:
      return t("authentication.apiErrors.server_error")

    case 403:
      return t("authentication.apiErrors.invalid_mail_or_pass")

    case 406:
      // If code is Not Acceptable (406) it means the user is signed up but not verified
      // so we redirect to the verify page
      changeAuthPage("verify")
      return t("authentication.apiErrors.unverified_mail")

    case 429:
      return t("authentication.apiErrors.too_many_tries")

    default:
      return t("authentication.apiErrors.general")
  }
}

function gtmSocialSignupEvent(socialName: string) {
  let event = props.isSignup ? "signup_attempt" : "login_attempt"

  gtm.push({
    event: event,
    method: socialName.toLowerCase(),
  } as DiscriminatedDataLayerArg)
}
</script>
