<template>
  <div class="flex flex-col gap-10">
    <h1 class="text-2xl font-semibold">{{ t("title") }}</h1>

    <div class="flex flex-col gap-3">
      <AuthSocialLogin is-signup />

      <UiButton
        class="gap-xxs p-xxs relative h-auto items-center bg-neutral-100 text-base leading-none"
        variant="alternative"
        @click="changeAuthPage('signupform')"
        :id="genClickId('auth', 'signup', 'email')"
      >
        <LucideMail class="start-xxs absolute top-1/2 -translate-y-1/2 text-black/50" :size="16" />
        {{ $t("authentication.common.signup_with_email") }}
      </UiButton>
    </div>

    <i18n-t class="font-light text-black/50 md:text-center" tag="span" keypath="already_signed_up">
      <template #action>
        <UiButton class="h-fit p-0 text-base font-medium" variant="link" @click="changeAuthPage('login')">
          {{ t("login") }}
        </UiButton>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
const { changeAuthPage } = useLogin()
const { t } = useI18n({ useScope: "local" })
</script>

<i18n lang="json">
{
  "he": {
    "title": "הרשמה",
    "already_signed_up": "כבר יש לך חשבון ב־FINQ? {action}",
    "login": "כניסה"
  },
  "en": {
    "title": "Sign up",
    "already_signed_up": "Already have an account with FINQ? {action}",
    "login": "Login"
  }
}
</i18n>
