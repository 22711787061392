<template>
  <div class="auth-widget--min-height flex flex-col gap-10 md:pt-10">
    <h1 class="text-2xl font-semibold">{{ $t("authentication.common.signup_with_email") }}</h1>

    <AuthManager
      is-signup
      :submitted="state.submitted"
      :loading="state.loading"
      @onSubmit="submit"
      @onLoading="state.loading = $event"
    />

    <Teleport to="#auth-widget-nav-slot" :disabled="!isMobile">
      <UiButton
        class="md:text-primary group h-fit max-h-6 w-fit p-0 text-base/6 font-medium text-black/50"
        variant="link"
        @click="changeAuthPage('signup')"
      >
        <LucideChevronLeft
          class="transition-all group-hover:-translate-x-1 rtl:rotate-180 rtl:group-hover:translate-x-1"
          :size="20"
        />
        {{ t("back_to_signup") }}
      </UiButton>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { HTTPError } from "ky"

const { changeAuthPage } = useLogin()
const { formState } = useAuthManager()
const { t } = useI18n({ useScope: "local" })

const state = reactive({ submitted: false, loading: false })

const apiError = useAuthApiError()
const gtm = useGtm()
const popup = usePopup()
const { isMobile } = useDisplay()

const { t: $t } = useI18n({ useScope: "global" })

const handleApiError = (code: number) => {
  switch (code) {
    case 409:
      return {
        message: "authentication.apiErrors.user_already_exist",
        field: "email",
      }

    default:
      return {
        message: "authentication.apiErrors.general",
      }
  }
}

const handleApiErrorPopups = (code: number) => {
  switch (code) {
    case 429:
      return popup.open({
        title: t("authentication.blocked.header_title"),
        content: t("authentication.apiErrors.too_many_tries"),
      })

    case 409:
      return null

    case 406:
      // If code is Not Acceptable (406) it means the user is signed up but not verified
      // so we redirect to the verify page
      return changeAuthPage("verify")

    case 500:
      return popup.open({ title: t("authentication.apiErrors.general") })
  }
}

const submit = async (form: { email: string; password: string; firstName: string; lastName: string }) => {
  state.submitted = true
  state.loading = true

  const body = {
    email: form.email,
    password: form.password,
    firstName: form.firstName,
    lastName: form.lastName,
  }

  Object.assign(formState, body)

  gtm.push({ event: "signup_attempt", method: "user-password", email: body.email })

  try {
    const user = await AuthService.signup(jsonClean(body))
    gtm.push({ event: "set_user_data", user_id: user.id, email: body.email })

    changeAuthPage("verify")
  } catch (err: any) {
    const code = (err as HTTPError).response.status

    apiError.error.value = {
      code,
      ...handleApiError(code)!,
    } as AuthApiError

    handleApiErrorPopups(code)
  } finally {
    state.loading = false
  }
}
</script>

<i18n lang="json">
{
  "he": {
    "back_to_signup": "חזרה לכל אפשרויות ההרשמה"
  },
  "en": {
    "back_to_signup": "All sign up options"
  }
}
</i18n>
